import React from 'react';
import Layout from '../components/Layout';
import Title from '../components/Title';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <Layout title="404 Page">
    <div className="c-content-box c-size-md">
      <div className="container">
        <Title main="PAGE NOT FOUND" />
        <div className="c-content-panel">
          <div className="c-body">
            <blockquote className="c-theme-border c-font-white">
              <p>
                Oops, it looks like this page does not exist – please, verify the page address or perhaps go back to our
                <Link to="/">
                  <strong style={{ color: 'white' }}> HOME PAGE</strong>
                </Link>
                .
              </p>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
